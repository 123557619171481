




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import myCollections from "@/views/myCollections/myCollections.vue";

@Component({
  components: {
    myCollections,
  },
})
export default class ExternalCollectionPage extends Mixins(Mixin) {}
