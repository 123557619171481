













































































































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import {
  Card,
  Tag,
  Button,
  Icon,
  Image as VanImage,
  Swipe,
  SwipeItem,
  Row,
  Col,
  Dialog,
  Checkbox,
  CheckboxGroup,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import MySwipers from "@/components/MySwiper.vue";
import myCollections from "./myCollections.vue";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Dialog.Component.name]: Dialog.Component,
    MySwipers,
  },
})
export default class Collection extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<member.CollectProjectRes>;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public tourList!: member.CollectTourDTO[];
  result: string[] = [];
  resultTwo: number[] = [];
  checkedAll = false;

  cards = "slides";
  @Prop()
  status!: boolean;
  mounted(): void {
    this.initSwiper();
  }
  initSwiper(): void {
    new Swiper(".swiper1", {
      slidesPerView: 2,
      spaceBetween: 10,
      // slidesOffsetAfter: 30,
      autoplay: false,
      loop: false,
      on: {
        // click: function (swiper: any, event: any) {
        //   console.log(77, vm.num, 88, event);
        // },
      },
    });
  }
  //全选
  checkAllChange(): void {
    this.result = [];
    this.resultTwo = [];
    if (this.checkedAll === true) {
      this.tourList.forEach((value) => {
        // console.log(value.collectId);
        this.result.push(value.tourId!);
      });
      this.projectList.forEach((item) => {
        if (item.productId) this.resultTwo.push(item.productId);
      });
    } else {
      this.result = [];
      this.resultTwo = [];
    }
  }
  checkedTotalItem(): void {
    let result = this.result.length + this.resultTwo.length;
    let resultTwo = this.tourList.length + this.projectList.length;
    if (result != resultTwo) {
      this.checkedAll = false;
    } else {
      this.checkedAll = true;
    }
  }

  tourCheckChange(): void {
    this.checkedTotalItem();
  }
  productCheckChange(): void {
    this.checkedTotalItem();
  }
  //删除的收藏
  delAll(): void {
    if (
      this.AppSource == "harmonyOS" &&
      !this.result.length &&
      !this.resultTwo.length
    ) {
      this.$toast("请选择要删除的项目");
      return;
    }
    this.delTour();
  }
  // 批量删除收藏巡演
  delTour(): void {
    monitorEvent("MyFav_DeleteItem", "取消我的收藏"); // 埋点：收藏订阅，取消我的收藏
    if (this.result.length > 0) {
      this.$api.memberApi.attentionAndCollection.delCollectTours(
        { tourIds: this.result },
        ({ data }) => {
          this.result = [];
          if (this.resultTwo.length > 0) {
            this.delProject();
          } else {
            (this.$parent as myCollections).getCollectTours();
            (this.$parent as myCollections).getCollection();
          }
        }
      );
    } else {
      if (this.resultTwo.length > 0) {
        this.delProject();
      } else {
        (this.$parent as myCollections).getCollectTours();
        (this.$parent as myCollections).getCollection();
      }
    }
  }
  // 批量删除收藏项目
  delProject(): void {
    this.$api.memberApi.attentionAndCollection.upCollectionIsDelInfo(
      { productIds: this.resultTwo.map(String) },
      (data) => {
        this.resultTwo = [];
        (this.$parent as myCollections).getCollectTours();
        (this.$parent as myCollections).getCollection();
      }
    );
  }
  goTourItem(item: member.CollectTourDTO): void {
    this.$router.push(`/tour/${item.tourId}`);
  }
  goProItem(item: member.CollectProjectRes): void {
    this.goToProjectDetail(item.productId, item.tourId);
  }
}
